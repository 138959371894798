"use strict";

// Class Definition
var KTLogin = function() {
	var _buttonSpinnerClasses = 'spinner spinner-right spinner-white pr-15';

	var _handleFormSignin = function() {
		var form = KTUtil.getById('kt_login_singin_form');
		var formSubmitUrl = KTUtil.attr(form, 'action');
		var formSubmitButton = KTUtil.getById('kt_login_singin_form_submit_button');

		if (!form) {
			return;
		}

		FormValidation
		    .formValidation(
		        form,
		        {
		            fields: {
						username: {
							validators: {
								notEmpty: {
									message: 'Username is required'
								}
							}
						},
						password: {
							validators: {
								notEmpty: {
									message: 'Password is required'
								}
							}
						}
		            },
		            plugins: {
						trigger: new FormValidation.plugins.Trigger(),
						submitButton: new FormValidation.plugins.SubmitButton(),
	            		//defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
						bootstrap: new FormValidation.plugins.Bootstrap({
						//	eleInvalidClass: '', // Repace with uncomment to hide bootstrap validation icons
						//	eleValidClass: '',   // Repace with uncomment to hide bootstrap validation icons
						})
		            }
		        }
		    )
		    .on('core.form.valid', function() {
				// Show loading state on button
				KTUtil.btnWait(formSubmitButton, _buttonSpinnerClasses, "Please wait");

				// Simulate Ajax request
				setTimeout(function() {
					KTUtil.btnRelease(formSubmitButton);
				}, 2000);
		    })
			.on('core.form.invalid', function() {
				//
		    });
    }

	var _handleFormForgot = function() {
		var form = KTUtil.getById('kt_login_forgot_form');
		var formSubmitUrl = KTUtil.attr(form, 'action');
		var formSubmitButton = KTUtil.getById('kt_login_forgot_form_submit_button');

		if (!form) {
			return;
		}

		FormValidation
		    .formValidation(
		        form,
		        {
		            fields: {
						email: {
							validators: {
								notEmpty: {
									message: 'Email is required'
								},
								emailAddress: {
									message: 'The value is not a valid email address'
								}
							}
						}
		            },
		            plugins: {
						trigger: new FormValidation.plugins.Trigger(),
						submitButton: new FormValidation.plugins.SubmitButton(),
	            		//defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
						bootstrap: new FormValidation.plugins.Bootstrap({
						//	eleInvalidClass: '', // Repace with uncomment to hide bootstrap validation icons
						//	eleValidClass: '',   // Repace with uncomment to hide bootstrap validation icons
						})
		            }
		        }
		    )
		    .on('core.form.valid', function() {
				// Show loading state on button
				KTUtil.btnWait(formSubmitButton, _buttonSpinnerClasses, "Please wait");

				// Simulate Ajax request
				setTimeout(function() {
					KTUtil.btnRelease(formSubmitButton);
				}, 2000);
		    })
			.on('core.form.invalid', function() {
				Swal.fire({
					text: "Sorry, looks like there are some errors detected, please try again.",
					icon: "error",
					buttonsStyling: false,
					confirmButtonText: "Ok, got it!",
					customClass: {
						confirmButton: "btn font-weight-bold btn-light-primary"
					}
				}).then(function() {
					KTUtil.scrollTop();
				});
		    });
    }

	var _handleFormSignup = function() {
		// Base elements
		var wizardEl = KTUtil.getById('kt_login');
		var form = KTUtil.getById('kt_login_signup_form');
		var wizardObj;
		var validations = [];

		if (!form) {
			return;
		}

		// Change event
		wizardObj.on('change', function (wizard) {
			KTUtil.scrollTop();
		});
    }

    // Public Functions
    return {
        init: function() {
            _handleFormSignin();
			_handleFormForgot();
			_handleFormSignup();
        }
    };
}();

// Class Initialization
jQuery(document).ready(function() {
    KTLogin.init();
});


window.onReady(function () {
    if (document.querySelector('#loginform')){
        document.querySelector('#loginform').addEventListener('submit', function (e) {
            e.preventDefault();
            let formAction = e.target.action;
            let formMethod = e.target.method;
            let formData = new FormData(e.target);
            
            window.HttpReq(formMethod, formAction, formData, {
                customSuccess: (response) => {
                    location.reload();
				},
				customBeforeError: (response) => {
                    if (response.status == 403){
						location.reload();
						throw new Error("Login onderbroken, herlaad de pagina."); 
					}
				},
            });
        });
	}
	
	if (document.querySelector('#registerform')){
        document.querySelector('#registerform').addEventListener('submit', function (e) {
            e.preventDefault();
            let formAction = e.target.action;
            let formMethod = e.target.method;
            let formData = new FormData(e.target);
            
            window.HttpReq(formMethod, formAction, formData, {
                customSuccess: (response) => {
                	document.querySelector('#registerform').style.display = 'none';
                	document.querySelector('#verifyform').style.display = 'block';
                },
            });
        });
	}

	if (document.querySelector('#forgotform')){
        document.querySelector('#forgotform').addEventListener('submit', function (e) {
            e.preventDefault();
            let formAction = e.target.action;
            let formMethod = e.target.method;
            let formData = new FormData(e.target);
            
            window.HttpReq(formMethod, formAction, formData, {
				customSuccessTitle: Lang('E-mail verstuurd'),
				customSuccessMessage: Lang('Als je een account hebt met dit e-mailadres, ontvang je daarop een nieuwe link om je wachtwoord te resetten.'),
            });
        });
	}
	
	if (document.querySelector('#verifyform')){
        document.querySelector('#verifyform').addEventListener('submit', function (e) {
            e.preventDefault();
            let formAction = e.target.action;
            let formMethod = e.target.method;
            let formData = new FormData(e.target);
            
            window.HttpReq(formMethod, formAction, formData, {
				customBefore: (response) => {
                	toastr.info("Nieuwe verificatie link versturen..");
				},
                customSuccess: (response) => {
					Swal.fire({
                        title: Lang(''),
                        text: Lang('Nieuwe verificatie link is verstuurd.'),
                        icon: "success",
                        confirmButtonColor: (typeof window.SwalConfirmButtonColor !== 'undefined') ? window.SwalConfirmButtonColor : "var(--primary)",
                        confirmButtonText: (typeof window.SwalConfirmButtonText !== 'undefined') ? window.SwalConfirmButtonText : Lang("OK"),
                        cancelButtonColor: (typeof window.SwalCancelButtonColor !== 'undefined') ? window.SwalCancelButtonColor : "var(--dark)",
                        cancelButtonText: (typeof window.SwalCancelButtonText !== 'undefined') ? window.SwalCancelButtonText : Lang("Cancel"),
                    });
				},
				customError: (response) => {
                	toastr.error("Nieuwe verificatie link kon niet verstuurd worden.");
				},
            });
        });
	}

	if (document.querySelector('#resetform')){
        document.querySelector('#resetform').addEventListener('submit', function (e) {
            e.preventDefault();
            let formAction = e.target.action;
            let formMethod = e.target.method;
            let formData = new FormData(e.target);
            
            window.HttpReq(formMethod, formAction, formData, {
				customBefore: (response) => {
                	toastr.info("Wachtwoord bijwerken..");
				},
                customSuccessTitle: ' ',
                customSuccessMessage: Lang('Je wachtwoord is bijgewerkt.'),
                customSuccessRedirect: '/',
            });
        });
	}
});